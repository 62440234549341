import { Page } from "static/js/app/modules/page";
import * as Api from "static/js/app/api/endpoints";
import * as Models from "static/js/app/models/__index";
import { FinanceQuoteDtoToFinanceQuote } from "static/js/app/api/dtoMapping";
import { SearchConfig } from "static/js/app/modules/search";
import StringFormatting from "static/js/app/utils/stringFormatting/stringFormatting"
import { SiteConfig } from "static/js/app/hugoSettings/siteConfig";
import { DealerBranchPublicInfo } from "static/js/app/models/dealerInfo";
import { Tabs } from "Foundation";

export class SearchBox {
    private searchboxEl: HTMLElement;
    private searchBoxForm: JQuery<HTMLFormElement>;
    private usedSearchBlock: JQuery<HTMLElement>;
    private searchBoxData: SearchBoxData = new SearchBoxData();
    private searchCascadingOpGrpToken: string = Models.Guid.create().toString();
    private filters: SearchFilter[]

    private readonly MakeField = "make";
    private readonly VehicleTypeField:string = "vehicletype";
    private readonly VehicleStatusField:string = "vehiclestatus";
    private readonly VehicleStatusClass: string = "vehicle-status-toggle";
    private readonly VehicleTypeClass: string = "vehicle-type-toggle";
    private readonly ModelField:string = "model";
    private readonly MinPriceField:string = "minprice";
    private readonly MaxPriceField:string = "maxprice";
    private readonly MinMonthlyPaymentField:string = "minmonthlypayment";
    private readonly MaxMonthlyPaymentField:string = "maxmonthlypayment";
    private readonly BerthField = "berth";
    private readonly DoorsField = "doors";
    private readonly EngineField = "fueltype"; // aka fuel type!
    private readonly BodyField = "body";
    private readonly ColourField = "colour";
    private readonly GearboxField = "gearbox";
    private readonly TransmissionField = "gearboxtype";
    private readonly EngineMinSizeField = "min-engine-size";
    private readonly EngineMaxSizeField = "max-engine-size";
    private readonly InsuranceGroupField = "insurance-group";
    private readonly KeywordsField = "keywords";
    private readonly MinMpgField = "min-mpg";
    private readonly MaxMpgField = "max-mpg";
    private readonly MinLengthField = "min-length";
    private readonly MaxLengthField = "max-length";
    private readonly MinUnladenWeightField = "min-unladen-weight";
    private readonly MaxUnladenWeightField = "max-unladen-weight";
    private readonly YearField = "year";
    private readonly BranchField = "branch"
    private readonly WebSectionField = "websection";
    private readonly SeatsField = "seats";
    private readonly UlezField = "ulez";
    private readonly EndLayoutField = "end-layout";
    private readonly BedroomLayoutField = "bedroom-layout";
    private readonly DealerBranches: DealerBranchPublicInfo[] = [];

    private readonly PriceFields:string[];

    private readonly AdvancedFieldDropDowns:string[] = [
        this.BerthField, this.DoorsField, this.EngineField, 
        this.BodyField,
        this.ColourField,
        this.GearboxField,
        this.TransmissionField,
        this.EngineMinSizeField, this.EngineMaxSizeField, 
        this.InsuranceGroupField, 
        this.MinMpgField, this.MaxMpgField, 
        this.MinLengthField, this.MaxLengthField, 
        this.MinUnladenWeightField, this.MaxUnladenWeightField, 
        this.YearField, this.BranchField,
        this.WebSectionField,
        this.SeatsField,
        this.EndLayoutField,
        this.BedroomLayoutField
       
    ];

    private readonly AdvancedFieldCheckboxes: string[] = [
        this.UlezField
    ]

    public constructor(
        private searchConfig: SearchConfig,
        private dealerBranches: DealerBranchPublicInfo[],
        initialVehicleStatus: Models.VehicleStatus,
        initialSelectedVehicleType: string,        
        isSearchPage: boolean,
        isFinanceSearch: boolean,
        selectedMakeOverride?: string,
        selectedModelOverride?: string
    ) {
        this.DealerBranches = dealerBranches;
        searchConfig.isFinanceSearch = isFinanceSearch;
          const selectedMake = selectedMakeOverride ?? Page.queryString[this.MakeField] ?? "";
          const selectedModel = selectedModelOverride ?? Page.queryString[this.ModelField] ?? "";
          const selectedMinPrice = (Page.queryString[this.MinPriceField] != null) ? parseInt(Page.queryString[this.MinPriceField]) : null;
          const selectedMaxPrice = (Page.queryString[this.MaxPriceField] != null) ? parseInt(Page.queryString[this.MaxPriceField]) : null;
          const selectedMinMonthlyPage = (Page.queryString[this.MinMonthlyPaymentField] != null) ? parseInt(Page.queryString[this.MinMonthlyPaymentField]) : null;
          const selectedMaxMonthlyPage = (Page.queryString[this.MaxMonthlyPaymentField] != null) ? parseInt(Page.queryString[this.MaxMonthlyPaymentField]) : null;
          this.filters = this.generateSearchFilter(searchConfig);
          this.PriceFields = this.generatePriceFields(isFinanceSearch);

          const selectedBerth: number = (Page.queryString[this.BerthField] != null) ? parseInt(Page.queryString[this.BerthField]) : null;
          const selectedDoors: number = (Page.queryString[this.DoorsField] != null) ? parseInt(Page.queryString[this.DoorsField]) : null;
          const selectedEngine: string = Page.queryString[this.EngineField] ?? "";
          const selectedBody: string = Page.queryString[this.BodyField] ?? "";
          const selectedColour: string = Page.queryString[this.ColourField] ?? "";
          const selectedGearbox: string = Page.queryString[this.GearboxField] ?? "";
          const selectedTransmission: string = Page.queryString[this.TransmissionField] ?? "";
          const selectedMinEngineSize: number = (Page.queryString[this.EngineMinSizeField] != null) ? parseInt(Page.queryString[this.EngineMinSizeField]) : null;
          const selectedMaxEngineSize: number = (Page.queryString[this.EngineMaxSizeField] != null) ? parseInt(Page.queryString[this.EngineMaxSizeField]) : null;
          const selectedInsuranceGroup: string = Page.queryString[this.InsuranceGroupField] ?? "";
          const selectedKeywords: string = Page.queryString[this.KeywordsField] ?? "";
          const selectedMinMpg: number = (Page.queryString[this.MinMpgField] != null) ? parseInt(Page.queryString[this.MinMpgField]) : null;
          const selectedMaxMpg: number = (Page.queryString[this.MaxMpgField] != null) ? parseInt(Page.queryString[this.MaxMpgField]) : null;

          const selectedMinLength: number = (Page.queryString[this.MinLengthField] != null) ? parseInt(Page.queryString[this.MinLengthField]) : null;
          const selectedMaxLength: number = (Page.queryString[this.MaxLengthField] != null) ? parseInt(Page.queryString[this.MaxLengthField]) : null;

          const selectedMinUnladenWeight: number = (Page.queryString[this.MinUnladenWeightField] != null) ? parseInt(Page.queryString[this.MinUnladenWeightField]) : null;
          const selectedMaxUnladenWeight: number = (Page.queryString[this.MaxUnladenWeightField] != null) ? parseInt(Page.queryString[this.MaxUnladenWeightField]) : null;

          const selectedYearBuilt: number = (Page.queryString[this.YearField] != null) ? parseInt(Page.queryString[this.YearField]) : null;
          const selectedBranch: number = (Page.queryString[this.BranchField] != null) ? parseInt(Page.queryString[this.BranchField]) : null;
          const selectedWebSection: string = Page.queryString[this.WebSectionField] ?? "";
          const selectedSeats: number = (Page.queryString[this.SeatsField] != null ) ? (parseInt(Page.queryString[this.SeatsField])): null;
          const selectedUlez: string = (Page.queryString[this.UlezField] != null ) ? (Page.queryString[this.UlezField]): null; 
          const selectedEndLayout: string = (Page.queryString[this.EndLayoutField] !=null ? Page.queryString[this.EndLayoutField] : null);
          const selectedBedroomLayout: string = (Page.queryString[this.BedroomLayoutField] !=null ? Page.queryString[this.BedroomLayoutField] : null);

        // get vehicle types, makes, models
        this.setSearchBoxData(
            // when the page is loading initially we run multiple data retrieval calls at the same time (example: /used/cars/ford/focus)
            // so we can't cancel other calls as this would mean we couldn't run them in parallel as each new call would cancel the previous one
            false,
            UpdateStartPoint.root,
            initialVehicleStatus,
            initialSelectedVehicleType,
            selectedMake,
            selectedModel,
            searchConfig.search_price_increment,
            selectedMinPrice,
            selectedMaxPrice,
            searchConfig.monthly_price_increment,
            selectedMinMonthlyPage,
            selectedMaxMonthlyPage,
            selectedBerth, selectedDoors, selectedEngine, 
            selectedBody, 
            selectedColour, 
            selectedGearbox, 
            selectedTransmission, 
            selectedMinEngineSize, selectedMaxEngineSize, 
            selectedInsuranceGroup, selectedKeywords, 
            selectedMinMpg, selectedMaxMpg, 
            selectedMinLength, selectedMaxLength, 
            selectedMinUnladenWeight, selectedMaxUnladenWeight, 
            selectedYearBuilt, selectedBranch, 
            selectedWebSection,
            selectedSeats,
            selectedUlez,
            selectedEndLayout,
            selectedBedroomLayout,
            !(isSearchPage) // don't use vehicle type fallback for vehicle types with no stock on search page
        ).then(() => {
            this.bindSearchboxDataToDomAndSetupEventHandlers(searchConfig, isSearchPage, selectedModel, selectedMake, isFinanceSearch);
        });
    }

    private bindSearchboxDataToDomAndSetupEventHandlers(searchBox: SearchConfig, isSearchPage: boolean, selectedModel: string, selectedMake: string, isFinanceSearch: boolean) {
        const whenReady: FrameRequestCallback = (timestamp: number) => {
            this.searchboxEl = document.getElementById(searchBox.dom_element_id);

            // wait for searchbox elements to be ready for update
            if (!this.searchboxEl ||
                !this.searchboxEl.querySelector(".search-count")) {
                window.requestAnimationFrame(whenReady);
            }
            else {
                this.initializeSearchBoxUI(searchBox);
                const useGrossVehCountForTotal = !isSearchPage;
                this.updateSearchCount((useGrossVehCountForTotal) ? this.searchBoxData.stockCounts.total :
                    (selectedModel != null && selectedModel != "") ? this.searchBoxData.stockCounts.currentModel :
                        (selectedMake != null && selectedMake != "") ? this.searchBoxData.stockCounts.currentMake :
                            this.searchBoxData.stockCounts.currentVehicleType);
                this.updateSearchBox(UpdateStartPoint.root);

                this.setupSearchboxChangeEventHandlers();
                this.addVehicleStatusTypeUpdater(this.VehicleStatusClass);
                //if radios --
                this.addVehicleTypeUpdater(this.VehicleTypeClass);
                this.enableFormSubmitButton();
                if (isFinanceSearch)
                {
                    let priceTypeDropDown =  this.searchBoxForm.find("[name='price_range_select']");

                    if((Page.queryString[this.MinMonthlyPaymentField] != null) || (Page.queryString[this.MaxMonthlyPaymentField] != null)) {
                        priceTypeDropDown.val(["monthly-payment"]);
                    }

                    if((Page.queryString[this.MinPriceField] != null) || (Page.queryString[this.MaxPriceField] != null)) {
                        priceTypeDropDown.val(["price"]);
                    }
                    
                    if (priceTypeDropDown)
                    {
                        this.togglePriceSearchDropdown();
                        this.addPriceTypeUpdater("price-select-toggle");
                    }
                }
            }
        };
        window.requestAnimationFrame(whenReady);
    }

    private setupSearchboxChangeEventHandlers() {
        this.filters.forEach(
            (searchFilter) => this.addDynamicSearchUpdater(searchFilter.classname));
    }

    private enableFormSubmitButton() {
        this.searchboxEl.getElementsByClassName('search-button')[0].removeAttribute("disabled");
    }

    private getDataCount(el: HTMLInputElement | HTMLSelectElement) : number
    {
        //  if(el.className==="search-make" || el.className==="search-model"){
        if (el == null || el == undefined)
        { 
            return 0;
        }
        let countstr = "0";
        //name returned by the option field
        if (el?.type == "select-one")
        //original code
        // if (el?.type == "select")
        //solut
    //  if (el?.type== "select-one" )
        {
            const selectEl = el as HTMLSelectElement;
            countstr = selectEl?.options[selectEl.selectedIndex]?.getAttribute("data-count") || "0";           
            
    
        } 
        else
        {
            // const selectEl = el as HTMLSelectElement;
            // countstr = selectEl?.options[selectEl.selectedIndex]?.getAttribute("data-count") || "0";           
            countstr = el?.getAttribute("data-count") || "0";           
           
        }
        try {
          
          return  Number.parseInt(countstr)
        }
        catch
        {
            return 0;
        }
    // }
    }

    private async onUserSearchBoxChange(evt: Event) {
        const el = evt.target as HTMLInputElement | HTMLSelectElement;
        
        const updateStartPoint: UpdateStartPoint =
            (el.name == this.VehicleStatusField)? UpdateStartPoint.vehicleStatus:
            (el.name == this.VehicleTypeField) ? UpdateStartPoint.vehicleType :
            (el.name == this.MakeField) ? UpdateStartPoint.make :
            (el.name == this.ModelField) ? UpdateStartPoint.model :
            UpdateStartPoint.root;
if(el.name == this.VehicleStatusField || el.name == this.VehicleTypeField || el.name == this.MakeField || el.name == this.ModelField){
    const dataCountAttr = this.getDataCount(el);

        if(dataCountAttr != null) {
           
            const count = updateStartPoint == UpdateStartPoint.vehicleStatus ? this.searchBoxData.stockCounts.currentVehicleType : dataCountAttr;
            this.updateSearchCount(count);
        }
    }


        let minPriceField = (this.getFilter(this.MinPriceField));
        let selectedMinPrice: number = (minPriceField.el.value != null && minPriceField.el.value != "") ? parseInt(minPriceField.el.value) : null;

        let maxPriceField = (this.getFilter(this.MaxPriceField));
        let selectedMaxPrice = (maxPriceField.el.value != null && maxPriceField.el.value != "") ? parseInt(maxPriceField.el.value) : null;
        if((el.name == this.MinPriceField) && (selectedMinPrice > 0 && selectedMinPrice > selectedMaxPrice)) {
            // reset max price field (value is less than min price so no longer makes sense)
            maxPriceField.el.value = "";
        }

        if((el.name == this.MaxPriceField) && (selectedMaxPrice > 0 && selectedMaxPrice < selectedMinPrice)) {
            // reset min price field (value is greated than max price so no longer makes sense)
            minPriceField.el.value = "";
        }

        this.updateSearchLink();

        

        if(updateStartPoint == UpdateStartPoint.root) {
            return Error("Unexpected start point in searchbox change");
        }

        await this.setSearchBoxDataFromUI(updateStartPoint);
        this.updateSearchBox(updateStartPoint);
    }

    private async setSearchBoxDataFromUI(updateStartPoint: UpdateStartPoint) {
        // clear the relevant sections of the searchbox data
        this.clearAndLockDependentSearchOptions(updateStartPoint);
        let selectedVehicleStatus = Models.VehicleStatusFromString( this.getRadioGroupValue(this.VehicleStatusField));
        let selectedVehicleType = this.getFilterElementValue(this.VehicleTypeField)?.replace(/s$/, "");
        let selectedMake: string = this.getFilter(this.MakeField).el.value;
        let selectedModel: string = this.getFilter(this.ModelField).el.value;
        let selectedMinPrice: number = (this.getFilter(this.MinPriceField).el.value != null && this.getFilter(this.MinPriceField).el.value != "") ? parseInt(this.getFilter(this.MinPriceField).el.value) : null;
        let selectedMaxPrice: number = (this.getFilter(this.MaxPriceField).el.value != null && this.getFilter(this.MaxPriceField).el.value != "") ? parseInt(this.getFilter(this.MaxPriceField).el.value) : null;

        let selectedMonthlyMinPayment: number = (this.searchConfig.isFinanceSearch && this.getFilter(this.MinMonthlyPaymentField).el.value != null && this.getFilter(this.MinMonthlyPaymentField).el.value != "") ? parseInt(this.getFilter(this.MinMonthlyPaymentField).el.value) : null;
        let selectedMonthlyMaxPayment: number = (this.searchConfig.isFinanceSearch && this.getFilter(this.MaxMonthlyPaymentField).el.value != null && this.getFilter(this.MaxMonthlyPaymentField).el.value != "") ? parseInt(this.getFilter(this.MaxMonthlyPaymentField).el.value) : null;

        const selectedBerth: number = parseInt(this.getFilter(this.BerthField)?.el?.value) ?? null;			  
        const selectedDoors: number = parseInt(this.getFilter(this.DoorsField)?.el?.value) ?? null;
        const selectedEngine: string = (this.getFilter(this.EngineField)?.el?.value) ?? null;
        const selectedBody: string = (this.getFilter(this.BodyField)?.el?.value) ?? null;
        const selectedColour: string = (this.getFilter(this.ColourField)?.el?.value) ?? null;
        const selectedGearbox: string = (this.getFilter(this.GearboxField)?.el?.value) ?? null;
        const selectedTransmission: string = (this.getFilter(this.TransmissionField)?.el?.value) ?? null;
        const selectedMinEngineSize: number = parseInt(this.getFilter(this.EngineMinSizeField)?.el?.value) ?? null;
        const selectedMaxEngineSize: number = parseInt(this.getFilter(this.EngineMaxSizeField)?.el?.value) ?? null;
        const selectedInsuranceGroup: string = (this.getFilter(this.InsuranceGroupField)?.el?.value) ?? null;
        const selectedKeywords: string = (this.getFilter(this.KeywordsField)?.el?.value) ?? null;
        const selectedMinMpg: number = parseInt(this.getFilter(this.MinMpgField)?.el?.value) ?? null;
        const selectedMaxMpg: number = parseInt(this.getFilter(this.MaxMpgField)?.el?.value) ?? null;

        const selectedMinLength: number = parseInt(this.getFilter(this.MinLengthField)?.el?.value) ?? null;
        const selectedMaxLength: number = parseInt(this.getFilter(this.MaxLengthField)?.el?.value) ?? null;

        const selectedMinUnladenWeight: number = parseInt(this.getFilter(this.MinUnladenWeightField)?.el?.value) ?? null;
        const selectedMaxUnladenWeight: number = parseInt(this.getFilter(this.MaxUnladenWeightField)?.el?.value) ?? null;

        const selectedYearBuilt: number = parseInt(this.getFilter(this.YearField)?.el?.value) ?? null;
        const selectedBranch: number = parseInt(this.getFilter(this.BranchField)?.el?.value) ?? null;
        const selectedWebSection: string = this.getFilter(this.WebSectionField)?.el?.value ?? null;
        const selectedSeats: number = parseInt(this.getFilter(this.SeatsField)?.el?.value) ?? null;
        const selectedUlez: string =  (this.getFilter(this.UlezField)?.el?.value) ?? null;
        const selectedBedroomLayout: string =  (this.getFilter(this.BedroomLayoutField)?.el?.value) ?? null;
        const selectedEndLayout: string =  (this.getFilter(this.EndLayoutField)?.el?.value) ?? null;


        await this.setSearchBoxData(
            // when the page is being interacted with only want the latest data retrieval call to succeed and the others to be cancelled
            // so as we are not running parallel data calls for the searchbox we can cancel other calls so that the only latest call succeeds
            (updateStartPoint == UpdateStartPoint.vehicleStatus ? false: true),
            updateStartPoint,
            selectedVehicleStatus,
            selectedVehicleType,
            (updateStartPoint >= UpdateStartPoint.make) ? selectedMake : null,
            (updateStartPoint >= UpdateStartPoint.model) ? selectedModel : null,
            this.searchConfig.search_price_increment,
            selectedMinPrice,
            selectedMaxPrice,
            this.searchConfig.monthly_price_increment,
            selectedMonthlyMinPayment,
            selectedMonthlyMaxPayment,
            selectedBerth, selectedDoors, 
            selectedEngine, selectedBody, 
            selectedColour,
            selectedGearbox,
            selectedTransmission,
            selectedMinEngineSize, selectedMaxEngineSize, 
            selectedInsuranceGroup, 
            selectedKeywords, 
            selectedMinMpg, selectedMaxMpg, 
            selectedMinLength, selectedMaxLength, 
            selectedMinUnladenWeight, selectedMaxUnladenWeight,
            selectedYearBuilt, 
            selectedBranch, 
            selectedWebSection,
            selectedSeats,
            selectedUlez,
            selectedEndLayout,
            selectedBedroomLayout,
            false
        );
    }

    private async setSearchBoxData(
        cancelExistingSearchboxDataCalls: boolean, updateStartPoint: UpdateStartPoint, 
        selectedVehicleStatus: Models.VehicleStatus,
        selectedVehicleType: string, selectedMake: string, selectedModel: string, 
        searchPriceBaseIncrement: number, 
        selectedMinPrice: number | null, selectedMaxPrice: number | null, 
        searchMonthlyPaymentIncrement: number | null, 
        selectedMinMonthlyPaymentField: number | null, selectedMaxMonthlyPaymentField:number | null, 
        selectedBerth: number, selectedDoors: number, 
        selectedEngine: string, 
        selectedBody: string, 
        selectedColour: string, 
        selectedGearbox: string, 
        selectedTransmission: string, 
        selectedEngineMinSize: number, 
        selectedEngineMaxSize: number, 
        selectedInsuranceGroup: string,
        selectedKeywords: string, 
        selectedMinMpg: number, selectedMaxMpg: number, 
        selectedMinLength: number, selectedMaxLength: number, 
        selectedMinUnladenWeight: number, selectedMaxUnladenWeight: number, 
        selectedYear: number, 
        selectedBranch: number, 
        selectedWebSection: string,
        selectedSeats: number,
        selectedUlez: string,
        selectedEndLayout: string,
        selectedBedroomLayout: string,
        fallbackToVehTypeWithLargestStock: boolean) {
        const tasks: Promise<void | any[]>[] = [];

        const callGrpOptions = new Models.CallGroupOptions(this.searchCascadingOpGrpToken, cancelExistingSearchboxDataCalls);

        const minPrice = (selectedMinPrice <= selectedMaxPrice) ? selectedMinPrice : null;
        const maxPrice = (selectedMaxPrice >= selectedMinPrice) ? selectedMaxPrice : null;

        // replace
        if (updateStartPoint == UpdateStartPoint.root ) {
           
            tasks.push(
              
                this.setRootLevelSearchBoxData(callGrpOptions, selectedVehicleStatus, selectedVehicleType, searchPriceBaseIncrement, minPrice, maxPrice, searchMonthlyPaymentIncrement, selectedMinMonthlyPaymentField, selectedMaxMonthlyPaymentField, 
                    selectedBerth, selectedDoors, selectedEngine, selectedBody, selectedColour, selectedGearbox, selectedTransmission,  
                    selectedEngineMinSize, selectedEngineMaxSize, selectedInsuranceGroup, selectedKeywords, 
                    selectedMinMpg, selectedMaxMpg, 
                    selectedMinLength, selectedMaxLength, 
                    selectedMinUnladenWeight, selectedMaxUnladenWeight, 
                    selectedYear, selectedBranch, selectedWebSection, selectedSeats, selectedUlez, selectedEndLayout, selectedBedroomLayout)
            );
        }
        if (updateStartPoint == UpdateStartPoint.vehicleStatus)
        {     
            tasks.push(      
            this.setRootLevelSearchBoxData(callGrpOptions, selectedVehicleStatus, selectedVehicleType, searchPriceBaseIncrement, minPrice, maxPrice, searchMonthlyPaymentIncrement, selectedMinMonthlyPaymentField, selectedMaxMonthlyPaymentField, 
                selectedBerth, selectedDoors, selectedEngine, selectedBody, selectedColour, selectedGearbox, selectedTransmission,  
                selectedEngineMinSize, selectedEngineMaxSize, selectedInsuranceGroup, selectedKeywords, 
                selectedMinMpg, selectedMaxMpg, 
                selectedMinLength, selectedMaxLength, 
                selectedMinUnladenWeight, selectedMaxUnladenWeight, 
                selectedYear, selectedBranch, selectedWebSection, selectedSeats, selectedUlez,selectedEndLayout, selectedBedroomLayout )
            .then(f=> { this.updateSearchCount(this.searchBoxData.stockCounts.currentVehicleType)}));
            //tasks.push(this.setVehicleTypeSearchBoxData(callGrpOptions,selectedVehicleStatus,selectedVehicleType).then(f=> { this.updateSearchCount(this.searchBoxData.stockCounts.currentVehicleType);}));
        }

        if (updateStartPoint == UpdateStartPoint.root || updateStartPoint == UpdateStartPoint.vehicleStatus || updateStartPoint == UpdateStartPoint.vehicleType) {
            tasks.push(
                this.setMakesSearchBoxData(selectedVehicleStatus,selectedVehicleType, callGrpOptions, selectedMake)
            );
        }

        if (updateStartPoint == UpdateStartPoint.root || updateStartPoint == UpdateStartPoint.make) {
            tasks.push(
                this.setModelsSearchBoxData(selectedMake,selectedVehicleStatus, selectedVehicleType, callGrpOptions, selectedModel)
            );
        }

        await Promise.all(tasks);

        // When requested to fallbackToVehTypeWithLargestStock (i.e on the homepage) on the off
        // chance the default selected vehicle type has no vehicles, select the vehicle type with
        // the most cars instead make sure we have selected an entry with stock
        if ((updateStartPoint == UpdateStartPoint.root) &&
            (fallbackToVehTypeWithLargestStock) &&
            (this.getFilter(this.VehicleTypeField).dataItems.filter(vc => vc.value == selectedVehicleType).length == 0)
        ) {
            await this.setSearchBoxDataUsingVehicleTypeWithLargestStock(callGrpOptions);
        }
    }

    private async setSearchBoxDataUsingVehicleTypeWithLargestStock(callGrpOptions: any) {
        const fallbackVehicleStatus: Models.VehicleStatus = this.searchConfig.default_vehicle_status;
        const vehicleTypesInStock = this.getFilter(this.VehicleTypeField).dataItems.filter(vc => vc.count > 0);
        if (vehicleTypesInStock.length > 0) {
            const fallbackVehicleType = vehicleTypesInStock
                .sort((a, b) => a.count < b.count ? 1 : -1 // sort by count descending
                )[0]
                .value;

            const fallbackVehicleTypeTasks: Promise<void | any[]>[] = [];
            
            fallbackVehicleTypeTasks.push(this.setVehicleTypeSearchBoxData(callGrpOptions, fallbackVehicleStatus,  fallbackVehicleType));

            if (fallbackVehicleType != null) {
                fallbackVehicleTypeTasks.push(this.setMakesSearchBoxData(fallbackVehicleStatus,fallbackVehicleType, callGrpOptions, ""));
            }
            await Promise.all(fallbackVehicleTypeTasks);
        }
    }

    private async setModelsSearchBoxData(selectedMake: string, selectedVehicleStatus: Models.VehicleStatus, selectedVehicleType: string, callGrpOptions: any, selectedModel: string) {
        if (selectedMake != null && selectedMake != '') {
            await Api.Vehicles.getModels(new Models.GetModelsOptions(selectedVehicleStatus, selectedVehicleType, selectedMake), callGrpOptions).then((vcs: Models.VehicleCount[]) => {
                this.getFilter(this.ModelField).dataItems = vcs.map(vc => new SearchBoxDataItem(vc.modelName, vc.modelValue, vc.count, vc.modelValue == selectedModel));

                const currentModel = vcs.filter((vc) => vc.modelValue == selectedModel)[0];
                this.searchBoxData.stockCounts.currentModel = (currentModel != null) ? currentModel.count : 0;
            });
        }
    }

    private async setMakesSearchBoxData(selectedVehicleStatus: Models.VehicleStatus, selectedVehicleType: string, callGrpOptions: any, selectedMake: string) {
        if (selectedVehicleType != null) {
            await Api.Vehicles.getMakes(new Models.GetMakesOptions(selectedVehicleStatus,selectedVehicleType), callGrpOptions).then((vcs: Models.VehicleCount[]) => {
                this.getFilter(this.MakeField).dataItems = vcs.map(vc => new SearchBoxDataItem(vc.makeName, vc.makeValue, vc.count, vc.makeValue == selectedMake));
                const currentMake = vcs.filter((vc) => vc.makeValue == selectedMake)[0];
                this.searchBoxData.stockCounts.currentMake = (currentMake != null) ? currentMake.count : 0;
            });
        }
    }

    private async setRootLevelSearchBoxData(callGrpOptions: Models.CallGroupOptions, selectedVehicleStatus: Models.VehicleStatus, selectedVehicleType: string, searchPriceBaseIncrement: number, selectedMinPrice: number, selectedMaxPrice: number, searchMonthlyPriceIncrement:number, selectedMinMonthlyPaymentField: number, selectedMaxMonthlyPaymentField: number,
        selectedBerth: number, selectedDoors: number, selectedEngine: string, selectedBody: string, selectedColour: string, 
        selectedGearbox: string, selectedTransmission: string, 
        selectedEngineMinSize: number, selectedEngineMaxSize: number, selectedInsuranceGroup: string, selectedKeywords: string, 
        selectedMinMpg: number, selectedMaxMpg: number, 
        selectedMinLength: number, selectedMaxLength: number, 
        selectedMinUnladenWeight: number, selectedMaxUnladenWeight: number, 
        selectedYear: number, selectedBranch: number, selectedWebSection: string, selectedSeats: number, selectedUlez: string, 
    selectedEndLayout: string, selectedBedroomLayout: string) {
        const setVehicleTypesTask = this.setVehicleTypeSearchBoxData(callGrpOptions, selectedVehicleStatus, selectedVehicleType);
        
        // this call must be made to run in parallel without cancelling the vehicle types call.
        // It only needs to be run on inital loading of the searchbox (i.e when update start point is root)
        const parallelCall = new Models.CallGroupOptions(callGrpOptions.opGrp, false);

        const searchTermsDto = await Api.Vehicles.getSearchTerms(new Models.GetSearchTermsOptions(selectedVehicleStatus), parallelCall);

        const getBranchName = (id: string) => this.DealerBranches?.filter(b=>b.id.toString() == id)?.pop()?.name ?? id;

        const searchTerms = searchTermsDto.map(spec => 
            {
                if(spec.fieldName == "branch_id") {
                    spec.acceptedSearchTerms = spec.acceptedSearchTerms.map(t => { t.name = getBranchName(t.name); return t; });
                } 
                
                return spec;
            }
        );

        this.setPriceMinMaxSearchBoxData(searchTerms, searchPriceBaseIncrement, selectedMinPrice, selectedMaxPrice, searchMonthlyPriceIncrement, selectedMinMonthlyPaymentField, selectedMaxMonthlyPaymentField);

        if(this.searchConfig?.advanced?.enabled) {
            this.setAdvancedSearchBoxData(searchTerms, selectedBerth, selectedDoors, selectedEngine, selectedBody, selectedColour, 
                selectedGearbox, selectedTransmission,
                selectedEngineMinSize, selectedEngineMaxSize, selectedInsuranceGroup, selectedKeywords, 
                selectedMinMpg, selectedMaxMpg, 
                selectedMinLength, selectedMaxLength, 
                selectedMinUnladenWeight, selectedMaxUnladenWeight, 
                selectedYear, selectedBranch, selectedWebSection, selectedSeats, selectedUlez, selectedEndLayout, selectedBedroomLayout);
        }

        await Promise.all([
            setVehicleTypesTask
        ]);
    }

    private setAdvancedSearchBoxData(searchTerms: Models.SearchTermSpec[], selectedBerth: number, selectedDoors: number, selectedEngine: string, selectedBody: string, 
        selectedColour: string,
        selectedGearbox: string,
        selectedTransmission: string,
        selectedEngineMinSize: number, selectedEngineMaxSize: number, selectedInsuranceGroup: string, selectedKeywords: string, 
        selectedMinMpg: number, selectedMaxMpg: number, 
        selectedMinLength: number, selectedMaxLength: number, 
        selectedMinUnladenWeight: number, selectedMaxUnladenWeight: number, 
        selectedYear: number, selectedBranch: number, selectedWebSection: string,
        selectedSeats: number, selectedUlez: string, selectedEndLayout: string, selectedBedroomLayout: string
        ) {
        const selectFields = [
            { name: "berth", htmlInputName: this.BerthField, selectedValue: selectedBerth },
            { name: "doors", htmlInputName: this.DoorsField, selectedValue: selectedDoors},
            { name: "fuel_type", htmlInputName: this.EngineField, selectedValue: selectedEngine },
            { name: "body_type", htmlInputName: this.BodyField, selectedValue: selectedBody },
            { name: "basic_colour", htmlInputName: this.ColourField, selectedValue: selectedColour },
            { name: "gearbox", htmlInputName: this.GearboxField, selectedValue: selectedGearbox },
            { name: "transmission", htmlInputName: this.TransmissionField, selectedValue: selectedTransmission },
            { name: "engine_capacity", htmlInputName: this.EngineMinSizeField, selectedValue: selectedEngineMinSize, rangeIncrement: 100, postfix: "cc" },
            { name: "engine_capacity", htmlInputName: this.EngineMaxSizeField, selectedValue: selectedEngineMaxSize, rangeIncrement: 100, postfix: "cc" },
            { name: "insurance_group", htmlInputName: this.InsuranceGroupField, selectedValue: selectedInsuranceGroup },
            { name: "mpg", htmlInputName: this.MinMpgField, selectedValue: selectedMinMpg, rangeIncrement: 10, postfix: "mpg" },
            { name: "mpg", htmlInputName: this.MaxMpgField, selectedValue: selectedMaxMpg, rangeIncrement: 10, postfix: "mpg" },
            { name: "length", htmlInputName: this.MinLengthField, selectedValue: selectedMinLength, rangeIncrement: 10 },
            { name: "length", htmlInputName: this.MaxLengthField, selectedValue: selectedMaxLength, rangeIncrement: 10 },
            { name: "unladened_weight", htmlInputName: this.MinUnladenWeightField, selectedValue: selectedMinUnladenWeight, rangeIncrement: 10 },
            { name: "unladened_weight", htmlInputName: this.MaxUnladenWeightField, selectedValue: selectedMaxUnladenWeight, rangeIncrement: 10 },
            { name: "year_built", htmlInputName: this.YearField, selectedValue: selectedYear},
            { name: "branch_id", htmlInputName: this.BranchField, selectedValue: selectedBranch},
            { name: "websection", htmlInputName: this.WebSectionField, selectedValue: selectedWebSection},
            { name: "keywords", htmlInputName: this.KeywordsField, selectedValue: selectedKeywords},
            { name: "seats", htmlInputName: this.SeatsField, selectedValue: selectedSeats}, 
            { name: "ulez_compliant", htmlInputName: this.UlezField, selectedValue: selectedUlez}, 
            { name: "end_layout", htmlInputName: this.EndLayoutField, selectedValue: selectedEndLayout}, 
            { name: "bedroom_layout", htmlInputName: this.BedroomLayoutField, selectedValue: selectedBedroomLayout}, 
        ];

        // HACK: until "keywords" is added values returned in ES API "terms" call we have to push the keywords term into the list of possible matches
        // SEE SNAPI-1096 comment (https://fridaymediagroup.atlassian.net/browse/SNAPI-1096?focusedCommentId=636948)
        searchTerms.push(new Models.SearchTermSpec("keywords", "text", null, false, 0, 0, null));

        searchTerms.forEach((searchTerm) => {
            const matchingFields = (selectFields.filter(f=>f.name == searchTerm.fieldName));

            if(matchingFields != null) {
                matchingFields.forEach((matchingField) => {
                    const filter = this.getFilter(matchingField.htmlInputName);
                    if(filter != null)
                    {
                        if(searchTerm.fieldType == "text") {
                            const valText = matchingField?.selectedValue?.toString();
                            filter.dataItems = (valText?.length) ? [new SearchBoxDataItem(valText, valText, -1, true)] : [];
                        }
                        else if (searchTerm.fieldType.indexOf("Boolean")> -1)
                        {
                            
                            filter.dataItems = [new SearchBoxDataItem("true", "true", -1, matchingField.selectedValue == "true")];                     
                        }
                        
                        else if(searchTerm.rangeSearch) {
                            const step = matchingField.rangeIncrement ?? 1;
                            const min = Math.floor(searchTerm.minRange / step) * step;
                            const max = (Math.ceil(Math.max(1, searchTerm.maxRange) / step)) * step;
                            filter.dataItems = [];
                            for(let i = min; i<=max; i = i + step)
                            {
                                const postfix = matchingField.postfix ?? "";
                                filter.dataItems.push(
                                    new SearchBoxDataItem(`${i}${postfix}`, i.toString(), -1, (matchingField.selectedValue == i.toString()))
                                );
                            }
                        } else {
                            filter.dataItems = searchTerm.acceptedSearchTerms.filter(f=> this.excludeValue(matchingField.name, f.value) ==false).map( spec =>                                                                 
                                new SearchBoxDataItem(spec.name, spec.value, -1, (matchingField.selectedValue == spec.value))                                
                            );
                        }
                    }
                });
            }
        });
    }
    // exclude values from dropdowns if necessary
    private excludeValue(fieldName: string, specValue: string): boolean
    {        
        if (fieldName?.toLowerCase() == "seats" && specValue == "0")
        {
            return true;
        }
        if (fieldName?.toLowerCase() == "doors" && specValue == "0")
        {
            return true;
        }
        return false;
    }

    private setPriceMinMaxSearchBoxData(stss: Models.SearchTermSpec[], searchPriceBaseIncrement: number, selectedMinPrice: number, selectedMaxPrice: number,
        searchMonthlyPriceIncrement: number, selectedMinMonthlyPaymentField:number,selectedMaxMonthlyPaymentField:number)
    {
        const priceSearchTermSpec = stss.filter(st => st.fieldName == 'price')[0];
        this.getFilter(this.MinPriceField).dataItems  = SearchBox.mapPriceSearchTermSpecToMinPriceSearchBoxDataItems(priceSearchTermSpec, searchPriceBaseIncrement, selectedMinPrice);
        this.getFilter(this.MaxPriceField).dataItems = SearchBox.mapPriceSearchTermSpecToMaxPriceSearchBoxDataItems(priceSearchTermSpec, searchPriceBaseIncrement, selectedMaxPrice);
        const financeSearchTermSpec = stss.filter(st => st.fieldName == 'finance_quotes.monthly_payment')[0];
        if (financeSearchTermSpec && this.searchConfig.isFinanceSearch)
        {
            this.getFilter(this.MinMonthlyPaymentField).dataItems  = SearchBox.mapPriceSearchTermSpecToMinPriceSearchBoxDataItems(financeSearchTermSpec, searchMonthlyPriceIncrement, selectedMinMonthlyPaymentField);
            this.getFilter(this.MaxMonthlyPaymentField).dataItems  = SearchBox.mapPriceSearchTermSpecToMaxPriceSearchBoxDataItems(financeSearchTermSpec, searchMonthlyPriceIncrement, selectedMaxMonthlyPaymentField);
        }
    }

    private async setVehicleTypeSearchBoxData(callGrpOptions: Models.CallGroupOptions, selectedVehicleStatus: Models.VehicleStatus, selectedVehicleType: string) {
        const vehicleTypeOrder = this.searchConfig?.advanced?.override_order_of_vehicle_types;
        const isValidTypeOrder = /[motorhome|campervan|caravan|van|bike|car|,]{30}/;
        await Api.Vehicles.getVehicleTypes(new Models.GetVehicleTypesOptions(selectedVehicleStatus), callGrpOptions).then((vcs: Models.VehicleCount[]) => {
            if (vehicleTypeOrder !=null && isValidTypeOrder.test(vehicleTypeOrder))
            {
                const typeOrder = vehicleTypeOrder.split(",");
                vcs = vcs.sort((a, b) => typeOrder.indexOf(a.typeValue) - typeOrder.indexOf(b.typeValue));
            }
            this.getFilter(this.VehicleTypeField).dataItems = vcs.map(vc => new SearchBoxDataItem(vc.typeName, vc.typeValue, vc.count, vc.typeValue == selectedVehicleType));            
            this.searchBoxData.stockCounts.total = vcs.reduce((acc, vehCount) => acc + vehCount.count, 0);
            const currentVehicleType = vcs.filter((vc) => vc.typeValue == selectedVehicleType)[0];
            this.searchBoxData.stockCounts.currentVehicleType = (currentVehicleType != null) ? currentVehicleType.count : 0;
        })
    }

    private mapBooleanFieldOptionValue(fieldValue: string): string{
        if (!fieldValue)
        {
            return "false";
        }
        return ( fieldValue == "1" || fieldValue == "true" ?"true":"false");
    }
    private mapBooleanFieldOptionName(fieldValue: string): string{
        if (!fieldValue)
        {
            return "No";
        }
        return ( fieldValue == "1" || fieldValue == "true" ?"Yes":"No");
    }
    private initializeFilterDomElReferences() {

        this.filters.forEach(f=> f.el = this.searchboxEl.getElementsByClassName(f.classname)[0] as HTMLSelectElement);
    }

    private clearAndLockDependentSearchOptions(updateStartPoint: UpdateStartPoint) {
        if (updateStartPoint == UpdateStartPoint.root ) {
            this.getFilter(this.VehicleTypeField).dataItems  = [];
        }

        const that = this;
        function disableSearchElements(names: string[]) {
            if(names == null) {
                return;
            }

            const filters = that.filters.filter((f:any) => names.indexOf(f.name) != -1);
            for(let i=0; i<names.length; i++) {
                const filter = filters[i];

                if(filter.el != null) {
                    filter.el.disabled = true;
                }
            }
        }        
        if (updateStartPoint <= UpdateStartPoint.vehicleType) {
            disableSearchElements([this.MakeField,this.ModelField]);
            this.getFilter(this.MakeField).dataItems  = [];
        }
        if (updateStartPoint <= UpdateStartPoint.make) {
            disableSearchElements([this.ModelField]);
            this.getFilter(this.ModelField).dataItems  = [];
        }
    }

    public static mapPriceSearchTermSpecToMinPriceSearchBoxDataItems(priceSearchTerm: Models.SearchTermSpec, baseIncrement: number, selectedPrice?: number | null): SearchBoxDataItem[] {
        priceSearchTerm.minRange--; // available steps must include one below min price
        var entries = SearchBox.mapPriceSearchTermSpecToSearchBoxDataItems(priceSearchTerm, baseIncrement, selectedPrice);
        
        return entries.slice(0, entries.length - 1);
    }

    public static mapPriceSearchTermSpecToMaxPriceSearchBoxDataItems(priceSearchTerm: Models.SearchTermSpec, baseIncrement: number, selectedPrice?: number | null): SearchBoxDataItem[] {
        var entries = SearchBox.mapPriceSearchTermSpecToSearchBoxDataItems(priceSearchTerm, baseIncrement, selectedPrice);

        return entries.slice(1, entries.length);
    }

    public static mapPriceSearchTermSpecToSearchBoxDataItems(priceSearchTerm: Models.SearchTermSpec, baseIncrement: number, selectedPrice?: number | null): SearchBoxDataItem[] {
        function roundToIncrement(val: number, roundDirection: string) {
              
            const multiplier =  (val > 20000 && val <50000)  ? 5
                              : (val > 50000 && val <100000) ? 10
                              : (val > 100000) ? 200 : 1;
               
            const roundedDown = val - (val % (baseIncrement*multiplier));
            
            return (roundDirection == 'down') ? roundedDown : roundedDown + (baseIncrement*multiplier);
        }

        const intFormat = new Intl.NumberFormat('en-GB', { style: 'decimal' });

        const range = {
            min: roundToIncrement(priceSearchTerm.minRange, 'down'),
            max: roundToIncrement(priceSearchTerm.maxRange, 'up')
        };

        const searchBoxDataItems: SearchBoxDataItem[] = [];

        const ranges = [
            [Math.max(0, range.min), Math.min(20000, range.max), 1],
            [20000, Math.min(50000, range.max), 5],
            [50000, Math.min(100000, range.max), 10],
            [100000, range.max, 200]
        ].map(arr => new PriceIncrementRange(arr[0], arr[1], arr[2]));


        if(ranges.length > 0) {
            const range = ranges[0];
            // add min price option below baseIncrement
            const name = "£" + intFormat.format(Number(range.min));
            const selected = (range.min == selectedPrice);

            searchBoxDataItems.push(new SearchBoxDataItem(name, range.min.toString(), -1, selected));
        }

        ranges.map(
            range => SearchBox.getPriceRanges(range.baseIncrementMultiplier * baseIncrement, range.min, range.max)
        ).reduce((a,b) => a.concat(b))
        .forEach(range =>{
            if (range.max >=  priceSearchTerm.minRange)
            {
            const name = "£" + intFormat.format(Number(range.max));
            const selected = (range.min != selectedPrice && range.max == selectedPrice);

            searchBoxDataItems.push(new SearchBoxDataItem(name, range.max.toString(), -1, selected));
            }
        });

        return searchBoxDataItems;
    }

    private static getPriceRanges(interval: number, start: number, maxPrice: number) {
        if(start > maxPrice) { return []; }

        const ranges: NumberRange[] = [];
        
        const start_ = Math.round((start+interval/2)/interval) * interval - interval; // get next higest interval
        for(let price=start_; price < maxPrice; price += interval ) {
            ranges[ranges.length] = new NumberRange(price, price+interval);
        }
        
        return ranges;
    }

    protected updateSearchBox(updateStartPoint: UpdateStartPoint) {
        if (updateStartPoint < UpdateStartPoint.model) {
            this.updateModelsDropDown(this.getFilter(this.ModelField).dataItems);

            if (updateStartPoint < UpdateStartPoint.make) {
                this.updateMakesDropDown(this.getFilter(this.MakeField).dataItems);

                if (updateStartPoint < UpdateStartPoint.vehicleType) {
                    
                    this.updatePriceDropDowns(this.PriceFields);
                    this.updateVehicleTypesDropDown(this.getFilter(this.VehicleTypeField).dataItems);
                    this.updateAdvancedFieldDropDowns(this.AdvancedFieldDropDowns);
                    this.updateAdvancedInputTextFields([this.KeywordsField]);
                    this.updateAdvancedFieldCheckboxes(this.AdvancedFieldCheckboxes);
                  
                }               
            }
        }
    }

    private initializeSearchBoxUI(searchConfig: SearchConfig) {
        this.searchboxEl = document.getElementById(searchConfig.dom_element_id);

        if (!this.searchboxEl) {
            return;
        }

        this.searchBoxForm = $(this.searchboxEl) as JQuery<HTMLFormElement>;
        this.usedSearchBlock = this.searchBoxForm.find('.search-count:first');


        // change submit to window location change
        this.searchBoxForm.on("submit", (evt) => { evt.preventDefault(); window.location.href = this.searchBoxForm.attr('action'); });

        this.searchCascadingOpGrpToken = Models.Guid.create().toString();
        this.initializeFilterDomElReferences();

        $(".advanced-search-button").on("click", (evt) => {
            const el = evt.target;
            evt.preventDefault();
            $('#advanced-search-box').toggle().find('.adv-search-field').toggle();

            if(el.innerHTML == searchConfig.advanced.advanced_search_button_text) {
                el.innerHTML = searchConfig.advanced.advanced_search_collapse_button_text;
            } else {
                el.innerHTML = searchConfig.advanced.advanced_search_button_text;
            }
        })
    }

    private togglePriceSearchDropdown( )
    {

        let priceTypeDropDown = this.searchBoxForm.find("[name='price_range_select']:checked");

        if (!priceTypeDropDown || !this.searchConfig.isFinanceSearch)
        {
            return;
        }
        if (priceTypeDropDown.val() ==="monthly-payment")
        {
            $(this.getFilter(this.MinMonthlyPaymentField).el.parentElement.parentElement).show();

            $(this.getFilter(this.MaxMonthlyPaymentField).el.parentElement.parentElement).show();


            this.getFilter(this.MinPriceField).el.value ='';
            $(this.getFilter(this.MinPriceField).el.parentElement.parentElement).hide();
            this.getFilter(this.MaxPriceField).el.value = '';
            $(this.getFilter(this.MaxPriceField).el.parentElement.parentElement).hide();
        }
        else{
            $(this.getFilter(this.MinMonthlyPaymentField).el.parentElement.parentElement).hide();
            this.getFilter(this.MinMonthlyPaymentField).el.value = '';
            this.getFilter(this.MaxMonthlyPaymentField).el.value = '';
            $(this.getFilter(this.MaxMonthlyPaymentField).el.parentElement.parentElement).hide();

            $(this.getFilter(this.MinPriceField).el.parentElement.parentElement).show();

            $(this.getFilter(this.MaxPriceField).el.parentElement.parentElement).show();
        }
        this.updateSearchLink();
    }

   

    private updateVehicleTypesDropDown(vehicleTypes: SearchBoxDataItem[]) {
        if (vehicleTypes.length == 0) {
            $(this.getFilter(this.VehicleTypeField).el).hide();            
        }       
        if (this.getFilter(this.VehicleTypeField).el.type == "radio")
        {            
            this.populateVehicleTypeRadios(this.searchboxEl.querySelector("#vehicle_type") as HTMLInputElement, vehicleTypes)
            this.addVehicleTypeUpdater(this.VehicleTypeClass);
        }
        else{
            this.populateVehicleTypes(this.getFilter(this.VehicleTypeField).el as HTMLSelectElement, vehicleTypes);
        }        
        this.filterReadyChange(this.VehicleTypeField, true);
        
    }

    private updateMakesDropDown(makes: SearchBoxDataItem[]) {
        this.populateMakes(this.getFilter(this.MakeField).el as HTMLSelectElement, makes);
        this.filterReadyChange(this.MakeField, true);
    }

    private updateModelsDropDown(models: SearchBoxDataItem[]) {
        this.populateModels(this.getFilter(this.ModelField).el as HTMLSelectElement, models);
        this.filterReadyChange(this.ModelField, true);
    }

    private updatePriceDropDowns(priceFields: string[]) {
        priceFields.forEach((p)=> {
            let priceField = this.getFilter(p);
            this.populatePriceSelect(priceField.el as HTMLSelectElement, priceField.dataItems);
            this.filterReadyChange(p, true);
        }
        );
    }

    private updateAdvancedInputTextFields(fields: string[]) {
        if(fields?.length) {
            let showAdvancedSearch = false;

            // set keywords field value
            fields.forEach((p)=> {
                let field = this.getFilter(p);
                if(field != null) {
                    if(field.dataItems[0]?.value?.length)
                    {
                        field.el.value = field.dataItems[0]?.value;
                        this.filterReadyChange(p, true);

                        if(field.el.value?.length && field.el.classList.contains(`adv-search-${field.name}`)) {
                            showAdvancedSearch = true;
                        }
                    }
                }
            });

            if(showAdvancedSearch) {
                const advSearchElWrap = $('#advanced-search-box');

                advSearchElWrap.show();

                advSearchElWrap.find(".adv-search-field").show();
            }
        }
    }

    private updateAdvancedFieldDropDowns(fields: string[]) {
        let showAdvancedSearch = false;

        fields.forEach((p)=> {
            let field = this.getFilter(p);
            if(field != null) {
                if (field.el.type == "radio")
                {

                }
                else
                {
                    this.populateSelectField(field.el as HTMLSelectElement , field.dataItems);
                }
                this.filterReadyChange(p, true);

                if(field.el.classList.contains(`adv-search-${field.el.name}`) && field.dataItems.filter(d=>d.selected).length) {
                    showAdvancedSearch = true;
                }
            }
        });

        if(showAdvancedSearch) {
            const advSearchElWrap = $('#advanced-search-box');

            advSearchElWrap.show();

            advSearchElWrap.find(".adv-search-field").show();
        }
    }

    private updateAdvancedFieldCheckboxes(fields: string[]) {
        let showAdvancedSearch = false;

        fields.forEach((p)=> {
            let field = this.getFilter(p);
            if(field != null) {
                
                const checkbox = field.el as HTMLInputElement;
                if (checkbox)
                {
                checkbox.checked = field.dataItems[0].selected
               
                
                this.filterReadyChange(p, true);

                if(field.el.classList.contains(`adv-search-${field.el.name}`) && field.dataItems.filter(d=>d.selected).length) {
                    showAdvancedSearch = true;
                }
                }
            }
        });

        if(showAdvancedSearch) {
            const advSearchElWrap = $('#advanced-search-box');

            advSearchElWrap.show();

            advSearchElWrap.find(".adv-search-field").show();
        }
    }

    private addPriceTypeUpdater(className:string)
    {
        let elements = this.searchboxEl.getElementsByClassName(className);
        [].forEach.call(elements, (f: HTMLElement) => f.addEventListener("change", (evt) => this.togglePriceSearchDropdown()));
    }

    private addVehicleStatusTypeUpdater(className:string)
    {
        let elements = this.searchboxEl.getElementsByClassName(className);
        [].forEach.call(elements, (f: HTMLElement) => f.addEventListener("change", (evt) => this.onUserSearchBoxChange(evt)));
    }

    private addVehicleTypeUpdater(className:string)
    {
        let elements = this.searchboxEl.getElementsByClassName(className);
        [].forEach.call(elements, (f: HTMLElement) => f.addEventListener("change", (evt) => this.onUserSearchBoxChange(evt)));
    }

    private addDynamicSearchUpdater(className:string) {
        const el = (this.searchboxEl.getElementsByClassName(className)[0] as HTMLElement);

        if(el != null) {
            el.addEventListener("change", (evt) => this.onUserSearchBoxChange(evt));
        }
    }    

    private generateSearchFilter(searchConfig: SearchConfig): SearchFilter[]
    {
       const searchFilters = new Array<SearchFilter>(       
            new SearchFilter(this.VehicleTypeField, null,false, "search-vehicle-type"),
            new SearchFilter(this.MakeField, null,false, "search-make"),
            new SearchFilter(this.ModelField, null,false, "search-model"),
            new SearchFilter(this.MinPriceField, null,false, "search-minPrice"),
            new SearchFilter(this.MaxPriceField, null,false, "search-maxPrice")
       );

       if(searchConfig.advanced?.enabled)
       {
           
            searchConfig.advanced.show_fields.forEach((advField) => {
               
                const ready = (advField.querystring_field_name == this.KeywordsField); // basic input text field - always ready

                var classNamePrefix = (advField.default_state == "hidden") ? "adv-search-" : "main-search-";
                
                searchFilters.push(new SearchFilter(advField.querystring_field_name, null, ready, `${classNamePrefix}${advField.querystring_field_name}`));
            })
       }
       
        if (searchConfig.isFinanceSearch)
        {
            searchFilters.push(
                new SearchFilter(this.MinMonthlyPaymentField, null,false, "search-minMonthlyPayment"),
                new SearchFilter(this.MaxMonthlyPaymentField, null,false, "search-maxMonthlyPayment")
            );
        }
        return searchFilters;
    }
    private generatePriceFields(isFinanceSearch: boolean): string[]
    {
       const searchFilters =  new Array<string>(
        this.MinPriceField,
        this.MaxPriceField);

        if (isFinanceSearch)
        {
            searchFilters.push(
                this.MinMonthlyPaymentField,
                this.MaxMonthlyPaymentField);
        }
        return searchFilters;
    }

    private defaultFilters: SearchFilter[] = [
        new SearchFilter(this.VehicleTypeField, null,false, "search-vehicle-type"),
        new SearchFilter(this.MakeField, null,false, "search-make"),
        new SearchFilter(this.ModelField, null,false, "search-model"),
        new SearchFilter(this.MinPriceField, null,false, "search-minPrice"),
        new SearchFilter(this.MaxPriceField, null,false, "search-maxPrice"),
        new SearchFilter(this.MinMonthlyPaymentField, null,false, "search-minMonthlyPayment"),
        new SearchFilter(this.MaxMonthlyPaymentField, null,false, "search-maxMonthlyPayment")
    ];



    private getFilter(name: string): SearchFilter {
        return this.filters.filter((filter) => filter.name == name)[0];
    }

    private filterReadyChange(name: string, isReady: boolean) {
        const filter = this.getFilter(name);
        filter.ready = isReady;

        if (isReady) {
            this.updateSearchLink();

            if(this.filters.filter(f=>f.ready).length == this.filters.length) {
                // all ready
                for(let i=0; i<this.filters.length; i++) {
                    if (this.filters[i].el)
                    {
                        this.filters[i].el.disabled = false;
                    }
                }
            }
        }
    }

    private updateSearchCount(count: number) {
        // this.usedSearchBlock[0].textContent = count.toString();
        if(count===0){
            this.usedSearchBlock[0].textContent ="";
          
        }
       
        else{
            this.usedSearchBlock[0].textContent = count.toString();
            
        }
       
       
    }

    private getRadioGroupValue(groupName: string): string | null
    {           
        const radioGroup = document.querySelector<HTMLInputElement>('input[name="'+groupName+'"]:checked');    
        return (radioGroup != null && radioGroup.value !=null ? radioGroup.value : null);        
    }

    private getFilterElementValue(filterName: string): string | null 
    {
        var filter = this.getFilter(filterName);
        if (filter == null || filter.el == null)
        {
            return null 
        }
        if (filter.el.type == "radio")
        {
            return this.getRadioGroupValue(filter.name);
        }
        if (filter.el.type == "checkbox")
        {
            const checkbox = filter.el as HTMLInputElement
            return (checkbox.checked ? "true": "false");
        }
        return filter.el.value;
    }

    private updateSearchLink() {
        var searchUrl = this.generateSearchUrl(
            Models.VehicleStatusFromString( this.getRadioGroupValue(this.VehicleStatusField)),
            this.getFilterElementValue(this.VehicleTypeField),
            this.getFilterElementValue(this.MakeField),
            this.getFilterElementValue(this.ModelField),
            this.getFilterElementValue(this.MinPriceField),
            this.getFilterElementValue(this.MaxPriceField),
            (this.searchConfig.isFinanceSearch ? this.getFilterElementValue(this.MinMonthlyPaymentField) : ''),
            (this.searchConfig.isFinanceSearch ? this.getFilterElementValue(this.MaxMonthlyPaymentField) : ''),
            this.getFilterElementValue(this.BerthField),
            this.getFilterElementValue(this.DoorsField),
            this.getFilterElementValue(this.EngineField),
            this.getFilterElementValue(this.BodyField),
            this.getFilterElementValue(this.ColourField),
            this.getFilterElementValue(this.GearboxField),
            this.getFilterElementValue(this.TransmissionField),
            this.getFilterElementValue(this.EngineMinSizeField),
            this.getFilterElementValue(this.EngineMaxSizeField),
            this.getFilterElementValue(this.InsuranceGroupField),
            this.getFilterElementValue(this.KeywordsField),
            this.getFilterElementValue(this.MinMpgField),
            this.getFilterElementValue(this.MaxMpgField),
            this.getFilterElementValue(this.MinLengthField),
            this.getFilterElementValue(this.MaxLengthField),
            this.getFilterElementValue(this.MinUnladenWeightField),
            this.getFilterElementValue(this.MaxUnladenWeightField),
            this.getFilterElementValue(this.YearField),
            this.getFilterElementValue(this.BranchField),
            this.getFilterElementValue(this.WebSectionField),
            this.getFilterElementValue(this.SeatsField),
            this.getFilterElementValue(this.UlezField),
            this.getFilterElementValue(this.EndLayoutField),
            this.getFilterElementValue(this.BedroomLayoutField)
        );
        
        this.searchBoxForm.attr('action', searchUrl);
    }

    private generateSearchUrl(
        vehicleStatus?: Models.VehicleStatus,
        vehicleType?: string,
        make?: string,
        model?: string,
        minPrice?: string | null | undefined,
        maxPrice?: string | null | undefined,
        minMonthlyPayment?: string | null | undefined,
        maxMonthlyPayment?: string | null | undefined,
        berth?: string | null | undefined, 
        doors?: string | null | undefined,
        engine?: string | null | undefined,
        body?: string | null | undefined,
        colour?: string | null | undefined,
        gearbox?: string | null | undefined,
        transmission?: string | null | undefined,
        engineMinSize?: string | null | undefined,
        engineMaxSize?: string | null | undefined,
        insuranceGroup?: string | null | undefined,
        keywords?: string | null | undefined,
        minMpg?: string | null | undefined,
        maxMpg?: string | null | undefined,
        minLength?: string | null | undefined,
        maxLength?: string | null | undefined,
        minUnladenWeight?: string | null | undefined,
        maxUnladenWeight?: string | null | undefined,
        year?: string | null | undefined,
        branch?: string | null | undefined,
        webSection?: string | null | undefined,
        seats?: string | null | undefined,
        ulez?: string | null | undefined, 
        endLayout?: string | null | undefined,
        bedroomLayout?: string | null | undefined,

        pageSize?: string | null | undefined
    ): string {
        const makePart = (make) ? '/' + make : '';
        const modelPart = (model) ? '/' + model : '';
        const fuelTypePart = (!make && engine) ? '/' + engine : '';
        const vehicleTypePart = (vehicleType) ? '/' + vehicleType : '';
        const urlMainPart = `/${vehicleStatus =="new" ? "new-vehicles" : "used"}${vehicleTypePart}`;

        const seoMainUrlPart = `${urlMainPart}${makePart}${modelPart}${fuelTypePart}/`;

        let qsParams: string[][] = [];

        if (minPrice != undefined && minPrice != null && minPrice.length > 0) {
            qsParams.push([this.MinPriceField, minPrice]);
        }


        if (maxPrice != undefined && maxPrice != null && maxPrice.length > 0) {
            qsParams.push([this.MaxPriceField, maxPrice]);
        }

        if (minMonthlyPayment != undefined && minMonthlyPayment != null && minMonthlyPayment.length > 0) {
            qsParams.push([this.MinMonthlyPaymentField, minMonthlyPayment]);
        }

        if (maxMonthlyPayment != undefined && maxMonthlyPayment != null && maxMonthlyPayment.length > 0) {
            qsParams.push([this.MaxMonthlyPaymentField, maxMonthlyPayment]);
        }

        if (berth != undefined && berth != null && berth.length > 0) {
            qsParams.push([this.BerthField, berth]);
        }
        
        if (doors != undefined && doors != null && doors.length > 0) {
            qsParams.push([this.DoorsField, doors]);
        }
        
        if (engine != undefined && engine != null && engine.length > 0) {
            if(make) { // when make is supplied, the fuel_type must become part of the querystring instead of the main seo url
                qsParams.push([this.EngineField, engine]);
            }
        }

        if (body != undefined && body != null && body.length > 0) {
            qsParams.push([this.BodyField, body]);
        }

        if (colour != undefined && colour != null && colour.length > 0) {
            qsParams.push([this.ColourField, colour]);
        }

        if (gearbox != undefined && gearbox != null && gearbox.length > 0) {
            qsParams.push([this.GearboxField, gearbox]);
        }

        if (transmission != undefined && transmission != null && transmission.length > 0) {
            qsParams.push([this.TransmissionField, transmission]);
        }
        
        if (engineMinSize != undefined && engineMinSize != null && engineMinSize.length > 0) {
            qsParams.push([this.EngineMinSizeField, engineMinSize]);
        }
        
        if (engineMaxSize != undefined && engineMaxSize != null && engineMaxSize.length > 0) {
            qsParams.push([this.EngineMaxSizeField, engineMaxSize]);
        }
        
        if (insuranceGroup != undefined && insuranceGroup != null && insuranceGroup.length > 0) {
            qsParams.push([this.InsuranceGroupField, insuranceGroup]);
        }
        
        if (keywords != undefined && keywords != null && keywords.length > 0) {
            qsParams.push([this.KeywordsField, keywords]);
        }
        
        if (minMpg != undefined && minMpg != null && minMpg.length > 0) {
            qsParams.push([this.MinMpgField, minMpg]);
        }
        
        if (maxMpg != undefined && maxMpg != null && maxMpg.length > 0) {
            qsParams.push([this.MaxMpgField, maxMpg]);
        }
        
        if (minLength != undefined && minLength != null && minLength.length > 0) {
            qsParams.push([this.MinLengthField, minLength]);
        }
        
        if (maxLength != undefined && maxLength != null && maxLength.length > 0) {
            qsParams.push([this.MaxLengthField, maxLength]);
        }

        if (minUnladenWeight != undefined && minUnladenWeight != null && minUnladenWeight.length > 0) {
            qsParams.push([this.MinUnladenWeightField, minUnladenWeight]);
        }
        
        if (maxUnladenWeight != undefined && maxUnladenWeight != null && maxUnladenWeight.length > 0) {
            qsParams.push([this.MaxUnladenWeightField, maxUnladenWeight]);
        }
        
        if (year != undefined && year != null && year.length > 0) {
            qsParams.push([this.YearField, year]);
        }
        
        if (branch != undefined && branch != null && branch.length > 0) {
            qsParams.push([this.BranchField, branch]);
        }

        if (seats != undefined && seats != null && seats.length > 0) {
            qsParams.push([this.SeatsField, seats]);
        }

        if (endLayout != undefined && endLayout != null && endLayout.length > 0) {
            qsParams.push([this.EndLayoutField, endLayout]);
        }

        if (bedroomLayout != undefined && bedroomLayout != null && bedroomLayout.length > 0) {
            qsParams.push([this.BedroomLayoutField, bedroomLayout]);
        }

        if (ulez != undefined && ulez != null && ulez.length > 0 && ulez =="true") {
            qsParams.push([this.UlezField, ulez]);
        }
        
        if (pageSize != undefined && pageSize != null && pageSize.length > 0) {
            qsParams.push(["items-per-page", pageSize]);
        }

        if (qsParams.length == 0) {
            return seoMainUrlPart;
        }

        var qsPart = qsParams
            .map(part => `${part[0]}=${part[1]}`)
            .join("&");

        return `${seoMainUrlPart}?${qsPart}`;
    }


    private populateVehicleTypes(selectEl: HTMLSelectElement, vehicleTypes: SearchBoxDataItem[]) {        
        while (selectEl.lastChild) {
            selectEl.removeChild(selectEl.lastChild);
        }

        for (var i = 0; i < vehicleTypes.length; i++) {
            var el = document.createElement('option');
            el.value = vehicleTypes[i].value + "s";
            el.setAttribute("data-count", vehicleTypes[i].count.toString());
            el.textContent = vehicleTypes[i].name + "s (" + vehicleTypes[i].count + ")";
            if (vehicleTypes[i].selected) {
                el.selected = true;
            }
            selectEl.appendChild(el);
        }

        if (vehicleTypes.length == 1) {
            $(selectEl).hide();
            $(selectEl).parent(".select-style").hide();
        }
        else{
            $(selectEl).show();
            $(selectEl).parent(".select-style").show();
        }

        // Classes for styling.
        let className = "search";

       
        if (vehicleTypes.length > 1) {
            className += "-multiveh";
        }       
        if (this.searchConfig.isFinanceSearch) {
            className+= "-finance";
        }
        this.searchBoxForm[0].firstElementChild.className =className

    }


  private buildVehicleTypeRadioElement(vehicleType: string, count:number, selected: boolean) : HTMLElement
  {
      const wrapperDiv = document.createElement("div");
      wrapperDiv.classList.add("vehicle-type-radio");
      wrapperDiv.classList.add(`vehicle-type-radio-${vehicleType}`);
      const radioButton = document.createElement("input");
      radioButton.type = "radio";
      radioButton.dataset.count = `${count}`;
      radioButton.name = "vehicletype";
      if (selected)
      {
        radioButton.setAttribute("checked","");
      }
      radioButton.value = `${vehicleType}s`;
      radioButton.classList.add("form-radio");
      radioButton.classList.add("vehicle-type-toggle");
      radioButton.classList.add("search-vehicle-type");
      radioButton.id = `edit-vehicle-type-radio-${vehicleType}`;
      
      const label = document.createElement("label");
      label.classList.add("option");
      label.setAttribute("for", `edit-vehicle-type-radio-${vehicleType}`);
      label.innerText = `${StringFormatting.titleCaseString(vehicleType)}s (${count})`;



      wrapperDiv.appendChild(radioButton);
      wrapperDiv.appendChild(label);

      
     return wrapperDiv;
  }
  private populateVehicleTypeRadios(radioEl: HTMLInputElement, vehicleTypes: SearchBoxDataItem[]) {
    const groupName = radioEl.parentElement
    while (radioEl.lastChild) {
        radioEl.removeChild(radioEl.lastChild);
        }
        let selectedType = vehicleTypes?.find(f=> f.selected == true );
        if (vehicleTypes.length > 0 && selectedType ==null)
        {
            vehicleTypes[0].selected = true;            
        }
        for (var i = 0; i < vehicleTypes.length; i++) {

            radioEl.appendChild(this.buildVehicleTypeRadioElement(vehicleTypes[i].value, vehicleTypes[i].count, vehicleTypes[i].selected));
        }

        if (vehicleTypes.length == 1) {
            $(radioEl).hide();
            $(radioEl).parent(".select-style").hide();
        }
        else{
            $(radioEl).show();
            $(radioEl).parent(".select-style").show();
        }

        // Classes for styling.
        let className = "search";

       
        if (vehicleTypes.length > 1) {
            className += "-multiveh";
        }       
        if (this.searchConfig.isFinanceSearch) {
            className+= "-finance";
        }
        this.searchBoxForm[0].firstElementChild.className =className
        
    } 
   

    private populateMakes(makesSelectEl: HTMLSelectElement, makes: SearchBoxDataItem[]) {
        for (var i = makesSelectEl.options.length - 1; i > -1; i--) {
            makesSelectEl.remove(i);
        }
        const totalVehicles = makes.reduce((acc, mk) => acc + mk.count, 0);
        const selectedMake = makes.filter(m => m.selected == true)[0];

        var el = document.createElement('option');
        el.value = '';
        el.textContent = "Make";
        el.setAttribute("data-count", totalVehicles.toString());
        el.selected = (selectedMake == null);
        makesSelectEl.appendChild(el); // append default "Select Make item"

        for (var i = 0; i < makes.length; i++) {
            var el = document.createElement('option');
            el.value = makes[i].value;
            el.setAttribute("data-count", makes[i].count.toString());
            el.textContent = makes[i].name + " (" + makes[i].count + ")";
            el.selected = (selectedMake != null && makes[i]?.value == selectedMake.value)
            makesSelectEl.appendChild(el);
        }
    }

    private populateModels(modelsSelectEl: HTMLSelectElement, models: SearchBoxDataItem[]) {
        for (var i = modelsSelectEl.options.length - 1; i > -1; i--) {
            modelsSelectEl.remove(i);
        }
        var totalVehicles = models.reduce((acc, mk) => acc + mk.count, 0);
        const selectedModel = models.filter(m => m.selected == true)[0];

        var el = document.createElement('option');
        el.value = '';
        el.textContent = "Model";
        el.setAttribute("data-count", totalVehicles.toString());
        el.selected = (selectedModel == null);
        modelsSelectEl.appendChild(el); // append default "Select Model item"

        for (var i = 0; i < models.length; i++) {
            var el = document.createElement('option');
            el.value = models[i].value;
            el.setAttribute("data-count", models[i].count.toString());
            el.textContent = models[i].name + " (" + models[i].count + ")";
            el.selected = (selectedModel != null && models[i]?.value == selectedModel.value)
            modelsSelectEl.appendChild(el);
        }
    }

    private populateSelectField(selectEl: HTMLSelectElement, dataItems: SearchBoxDataItem[]) {
        for (var i = selectEl.options.length - 1; i > 0; i--) {
            selectEl.remove(i);
        }

        for (var i = 0; i < dataItems.length; i++) {
            var el = document.createElement('option');
            el.value = dataItems[i].value;
            if(dataItems[i].count != -1) {
                el.setAttribute("data-count", dataItems[i].count.toString());
            }
            el.textContent = dataItems[i].name;
            if (dataItems[i].selected) {
                el.selected = true;
            }
            selectEl.appendChild(el);
        }

        if (dataItems.length == 1) {
            $(selectEl).hide();
            $(selectEl).parent(".select-style").hide();
        }
        else{
            $(selectEl).show();
            $(selectEl).parent(".select-style").show();
        }

    }

    private populatePriceSelect(priceSelectEl: HTMLSelectElement, prices: SearchBoxDataItem[]) {
        var optionsFrag = document.createDocumentFragment();
        for (var i = priceSelectEl.options.length - 1; i > 0; i--) {
            priceSelectEl.remove(i);
        }       
        for (var i = 0; i < prices.length; i++) {
            var el = document.createElement('option');
            el.value = prices[i].value;
            el.textContent = prices[i].name;
            el.selected = prices[i].selected;
            optionsFrag.appendChild(el);
        }

        priceSelectEl.appendChild(optionsFrag);
    }
}

enum UpdateStartPoint
{
    root = 0,
    vehicleStatus = 1,
    vehicleType = 2,
    make = 3,
    model = 4
}

class SearchBoxDataItem {
    public constructor(
        public name: string,
        public value: string,
        public count: number,
        public selected: boolean
    ) { }
}

class SearchBoxData {
    public constructor(
        public count: number = 0,
        public stockCounts: SearchBoxDataStockCounts = new SearchBoxDataStockCounts()
    ) { }
}

class SearchBoxDataStockCounts {
    public constructor(
        public total: number = 0,
        public currentVehicleStatus: number = 0,
        public currentVehicleType: number = 0,
        public currentMake: number = 0,
        public currentModel: number = 0
    ) {}
}

class SearchFilter {
    public constructor(
        public name: string,
        public el: HTMLSelectElement | HTMLInputElement ,
        public ready: boolean,
        public classname: string,
        public dataItems: SearchBoxDataItem[] = []
    ){

    }
}

class PriceIncrementRange
{
    public constructor(
        public min: number,
        public max: number,
        public baseIncrementMultiplier: number
    ) {}
}

class NumberRange
{
    public constructor(
        public min: number,
        public max: number
    ) {}
}


